import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useSpring, animated, config } from "react-spring"

const SpringApp = () => {
  const props = useSpring({
    to: async (next, cancel) => {
      await next({ opacity: 1, color: "#ffaaee" })
      await next({ opacity: 0.8, color: "rgb(14,26,19)" })
    },
    from: { opacity: 0, color: "red" },
  })
  return <animated.h1 style={props}>hello</animated.h1>
}

const Num = () => {
  const props = useSpring({
    number: 100,
    from: { number: 0 },
    config: config.molasses,
  })
  return <animated.span>{props.number}</animated.span>
}

const Animates = () => (
  <Layout>
    <SEO title="Animates Test" />
    <h1>Animates Test</h1>
    <SpringApp />
    <Num />
    <p>Welcome to Animates Test</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Animates
